import React from "react"

function Accordion(props) {
  function toggleAccordion() {
    props.check(props.accKey)
  }

  return (
    <div className="flex flex-col my-1">
      <div style={{ transition: `all ${props.delay}` }}>
        <h2
          onClick={toggleAccordion}
          className={`cursor-pointer accordion-title accordion-button float-left text-pink-900 accordion-button__${props.accKey} hover:bg-yellow-100 p-1 rounded flex flex-row items-center`}
        >
          {props.icon}
          <span className="ml-2">{props.title}</span>
        </h2>
      </div>
      <div
        className={`accordion-content accordion-content__${props.accKey}`}
        style={{
          maxHeight: "0",
          overflow: "hidden",
          transition: `all ${props.delay}`,
        }}
      >
        {props.content}
      </div>
    </div>
  )
}

export default Accordion
