import React from "react"
import aboutData from "../../data/about.json"

const Experience = (props) => {
  const experience = aboutData.experience

  const rows = experience.map((exp) => (
    <div className="mb-2">
      <p class="font-semibold">
        {exp.title}, {exp.start}-{exp.end === 9999 ? <i>Present</i> : exp.end}
      </p>
      {exp.url ? (
        <span>
          <a className="ml-4" href={exp.url}>
            {exp.company}
          </a>
          , {exp.location}
        </span>
      ) : (
        <span className="ml-4">
          {exp.company}, {exp.location}
        </span>
      )}
    </div>
  ))
  return <div className="accordion-item">{rows}</div>
}

export default Experience
