import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Image = () => {
  return (
    <div className="w-4/5 mb-4">
      <StaticImage
        src="../images/profile.jpg"
        class="shadow-lg rounded-full max-w-full h-auto align-middle border-none"
      />
    </div>
  )
}

export default Image
