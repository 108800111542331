import React, { Fragment } from "react"
import aboutData from "../../data/about.json"

const Education = (props) => {
  const education = aboutData.education

  const rows = education.map((edu) => (
    <div className="mb-2">
      <p class="font-semibold">
        {edu.degree}, {edu.start}-{edu.end === 9999 ? <i>Present</i> : edu.end}
      </p>
      <p class="ml-4">{edu.institution}</p>
      {edu.thesis_name ? (
        edu.thesis_url ? (
          <Fragment>
            <p className="ml-4">
              {edu.thesis_name}: <a href={edu.thesis_url}>{edu.thesis_title}</a>
            </p>
          </Fragment>
        ) : (
          <p className="ml-4">
            {edu.thesis_name}: {edu.thesis_title}
          </p>
        )
      ) : (
        ""
      )}
    </div>
  ))
  return <div className="accordion-item">{rows}</div>
}

export default Education
