import React from "react"

import Layout from "../components/layout"
import Introduction from "../components/front-page/introduction"
import Accordion from "../components/Accordion/Accordion"
import Education from "../components/front-page/education"
import Experience from "../components/front-page/experience"
import AboutMe from "../components/front-page/about"

import { FaGraduationCap } from "react-icons/fa"
import { FaPortrait } from "react-icons/fa"
import { FaSuitcase } from "react-icons/fa"

function HomePage() {
  return (
    <Layout showFooter={false}>
      {/* Left Side */}
      <div className="w-full min-w-250px sm:w-2/5 px-2 mt-2">
        <Introduction />
      </div>
      {/* Right Side */}
      <div class="w-full sm:w-3/5 px-2 mt-2">
        <Accordion
          uniqueId="appleBerryIsPrettyGood"
          delay="0.5s"
          elements={[
            {
              icon: <FaPortrait />,
              title: "About Me",
              content: <AboutMe />,
            },
            {
              icon: <FaGraduationCap />,
              title: "Education",
              content: <Education />,
            },
            {
              icon: <FaSuitcase />,
              title: "Experience",
              content: <Experience />,
            },
          ]}
          defaultExpand={0} //index of 'elements' to expand at the beginning
        />
      </div>
    </Layout>
  )
}

export default HomePage
