import React from "react"
import Footer from "../../components/footer"

import Image from "../image"

import { FaTwitter, FaLinkedinIn, FaGithub } from "react-icons/fa"

export default function Introduction() {
  return (
    <div className="flex flex-col justify-center items-center p-4 rounded h-100 shadow bg-pink-900">
      <div className="flex flex-col items-center">
        <Image />
        <div className="text-center">
          <h1 className="my-1 text-white">Urja Acharya</h1>
          <h2 className="my-1 text-white">Software Engineer</h2>
          <h3 className="my-1 text-white">San Francisco Bay Area</h3>
        </div>
        <div className="my-4 w-full flex flex-row justify-center">
          <a
            className="social-icon"
            href="https://www.linkedin.com/in/urjaacharya/"
          >
            <FaLinkedinIn />
          </a>
          <a className="social-icon" href="https://github.com/urjaacharya">
            <FaGithub />
          </a>
        </div>
        <Footer />
      </div>
    </div>
  )
}
